<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    html() {
      return this.block?.fields?.HTML;
    },
  },
};
</script>

<template>
  <div
    v-html="html"
  >
  </div>
</template>
